import type {HandleClientError} from '@sveltejs/kit';

// Отключаем дефолтное поведение при дропе файлов
function preventDefaultDD() {
    window.addEventListener(
        'dragover',
        function (e) {
            e.preventDefault();
        },
        false
    );

    window.addEventListener(
        'drop',
        function (e) {
            e.preventDefault();
        },
        false
    );
}

preventDefaultDD();

export const handleError: HandleClientError = async ({error, event, status, message}) => {
    console.error('Client error', error, status, message, event.url);

    return {
        message: message,
    };
};
