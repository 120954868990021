import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84')
];

export const server_loads = [3];

export const dictionary = {
		"/(public)": [~6,[2]],
		"/admin": [57,[4]],
		"/admin/blacklist": [58,[4]],
		"/admin/blog": [59,[4]],
		"/admin/categories": [60,[4]],
		"/admin/cities": [61,[4]],
		"/admin/parser-test": [62,[4]],
		"/admin/proxy": [63,[4]],
		"/admin/suppliers": [64,[4]],
		"/admin/users": [65,[4]],
		"/admin/website-requests": [66,[4]],
		"/(public)/agreement-add-rules": [8,[2]],
		"/(public)/agreement": [7,[2]],
		"/(public)/billing/payment-result/[gate]/[result]": [~9],
		"/(public)/blog": [~10,[2]],
		"/(public)/blog/[slug]": [~11,[2]],
		"/(public)/catalog/add": [13,[2]],
		"/(public)/catalog/[[slug1]]/[[slug2]]": [~12,[2]],
		"/(public)/faq": [14,[2]],
		"/(public)/howto": [15,[2,3]],
		"/(public)/howto/additional-css-selectors": [16,[2,3]],
		"/(public)/howto/ai": [17,[2,3]],
		"/(public)/howto/ai/generate": [18,[2,3]],
		"/(public)/howto/ai/models": [~19,[2,3]],
		"/(public)/howto/ai/points": [20,[2,3]],
		"/(public)/howto/ai/prompt-writing": [21,[2,3]],
		"/(public)/howto/automata": [22,[2,3]],
		"/(public)/howto/automata/edit": [23,[2,3]],
		"/(public)/howto/button": [24,[2,3]],
		"/(public)/howto/erange": [25,[2,3]],
		"/(public)/howto/filestorage": [26,[2,3]],
		"/(public)/howto/format": [27,[2,3]],
		"/(public)/howto/format/[id]": [~28,[2,3]],
		"/(public)/howto/mods": [29,[2,3]],
		"/(public)/howto/mods/cols-replace": [31,[2,3]],
		"/(public)/howto/mods/cols-sort": [32,[2,3]],
		"/(public)/howto/mods/cols": [30,[2,3]],
		"/(public)/howto/mods/copying": [33,[2,3]],
		"/(public)/howto/mods/deduplication": [34,[2,3]],
		"/(public)/howto/mods/filter": [35,[2,3]],
		"/(public)/howto/mods/images": [36,[2,3]],
		"/(public)/howto/mods/images/count": [37,[2,3]],
		"/(public)/howto/mods/images/cut": [38,[2,3]],
		"/(public)/howto/mods/images/format": [39,[2,3]],
		"/(public)/howto/mods/images/overlay": [40,[2,3]],
		"/(public)/howto/mods/images/resize": [41,[2,3]],
		"/(public)/howto/mods/images/text": [42,[2,3]],
		"/(public)/howto/mods/price": [43,[2,3]],
		"/(public)/howto/mods/publish": [44,[2,3]],
		"/(public)/howto/mods/sort": [45,[2,3]],
		"/(public)/howto/parser-categories": [46,[2,3]],
		"/(public)/howto/parser-compare": [47,[2,3]],
		"/(public)/howto/referral": [48,[2,3]],
		"/(public)/howto/social": [49,[2,3]],
		"/my": [~67,[5]],
		"/my/catalog/add": [69,[5]],
		"/my/catalog/private": [70,[5]],
		"/my/catalog/[[slug1]]/[[slug2]]": [68,[5]],
		"/my/parser": [71,[5]],
		"/my/parser/automata": [73,[5]],
		"/my/parser/automata/[automataId]": [74,[5]],
		"/my/parser/compare/[queueId1]/[queueId2]": [75,[5]],
		"/my/parser/credentials": [76,[5]],
		"/my/parser/filestorage": [77,[5]],
		"/my/parser/result/[queueId]": [78,[5]],
		"/my/parser/retry/[queueId]": [79,[5]],
		"/my/parser/[domain]": [72,[5]],
		"/my/profile": [80,[5]],
		"/my/profile/tariffs": [81,[5]],
		"/my/supplier/my": [82,[5]],
		"/my/supplier/my/[id]": [83,[5]],
		"/my/supplier/tariffs": [84,[5]],
		"/(public)/privacy-policy": [50,[2]],
		"/(public)/reviews": [~51,[2]],
		"/(public)/supplier": [52,[2]],
		"/(public)/supplier/widget": [53,[2]],
		"/(showcase)/s/[id]": [~56],
		"/(public)/tariffs": [~54,[2]],
		"/(public)/what-can": [55,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';